<template>
	<div :class="{'block-inner': true, 'has-file': fileUrls.length, 'align-right': block.meta.align === 'right', 'align-center': block.meta.align === 'center'}">
		<div v-if="fileUrls.length" class="current-file" :style="{ 'max-width': block.meta.maxWidth }">
			<img :src="fileUrls[0]" />
		</div>

		<div v-if="isFailed">
			<h2>Upload failed.</h2>
			<pre>{{ uploadError }}</pre>
		</div>

		<DropZone v-else :is-saving="isSaving" :accept-types="acceptTypes" @upload="filesChange" />
	</div>
</template>

<script>
	import DropZone from '../DropZone';
	import editorUploadMixin from '../../mixins/editorUploadMixin';

	export default {
		components: {
			DropZone
		},
		mixins: [editorUploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				acceptTypes: [
					{
						label: 'Image',
						code: 'image',
						mimeTypes: [
							'image/jpeg',
							'image/png',
							'image/gif',
							'image/bmp',
							'image/svg'
						]
					}
				],
				allowOverwrite: true
			};
		},
		watch: {
			currentStatus(newStatus) {
				// If upload success
				if(newStatus === 2 && !this.block.meta) {
					this.block.meta = {
						align: 'left'
					};
				}
			}
		},
		mounted() {
			this.reset();
		},
		created() {
			if(typeof this.block.meta !== 'object') {
				this.$set(this.block, 'meta', {});
			}

			if(!Object.keys(this.block.meta).length) {
				this.$set(this.block.meta, 'align', 'left');
				this.$set(this.block.meta, 'maxWidth', '100%');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.dropzone::v-deep {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.file-upload {
			opacity: 0.5;

			&:hover {
				opacity: 0.85;
				backdrop-filter: blur(3px);
			}
		}
	}

	.block-inner {
		display: flex;
		flex-flow: row nowrap;

		&.align-right {
			justify-content: flex-end;
		}

		&.align-center {
			justify-content: center;
		}
	}
</style>